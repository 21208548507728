// SCRIPT TO CHECK IF HOMEPAGE FIRST IMAGE IS LOADED
jQuery('.main_image_section').imagesLoaded( { background: true }, function() {
    
    // ADDED homepageImageLoaded class to preloader
    jQuery('#wp-preloader').addClass('homepageImageLoaded');

    // fadeout preloader if all required elements/scrips have loaded
    jQuery('#wp-preloader.scriptsLoaded.homepageImageLoaded.logoLoaded').delay(250).fadeOut("slow");
        
    setTimeout(wp_remove_preloader, 2000);
    function wp_remove_preloader() {	
        jQuery('#wp-preloader.scriptsLoaded.homepageImageLoaded.logoLoaded').remove();
    }

});


// SCRIPT TO CHECK IF SITE LOGO HAS LOADED
jQuery('#logo-wrapper').imagesLoaded( function() {

    // ADDED logoLoaded class to preloader
    jQuery('#wp-preloader').addClass('logoLoaded');


    // fadeout preloader if all required elements/scrips have loaded
    jQuery('#wp-preloader.scriptsLoaded.homepageImageLoaded.logoLoaded').delay(250).fadeOut("slow");
        
    setTimeout(wp_remove_preloader, 2000);
    function wp_remove_preloader() {	
        jQuery('#wp-preloader.scriptsLoaded.homepageImageLoaded.logoLoaded').remove();
    }

});
    
    
// FIRED AFRER SCRIPTS ARE LOADED
jQuery(window).ready(function () {

    // ADDED scriptsLoaded class to preloader
    jQuery('#wp-preloader').addClass('scriptsLoaded');

    // fadeout preloader if all required elements/scrips have loaded
    jQuery('#wp-preloader.scriptsLoaded.homepageImageLoaded.logoLoaded').delay(250).fadeOut("slow");
        
    setTimeout(wp_remove_preloader, 2000);
    function wp_remove_preloader() {	
        jQuery('#wp-preloader.scriptsLoaded.homepageImageLoaded.logoLoaded').remove();
    }
});